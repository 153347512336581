<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 16 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 8H4V6.66667H0V8ZM0 0V1.33333H12V0H0ZM0 4.66667H8V3.33333H0V4.66667Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'IcSort',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>
